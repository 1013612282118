// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import AsyncStorage from '@react-native-async-storage/async-storage'; // Добавлен импорт AsyncStorage
// import api from '../axios';

// // Экшены для работы с темами
// export const fetchThemes = createAsyncThunk(
//   'texts/fetchThemes',
//   async (courseId, { rejectWithValue }) => {
//     try {
//       const response = await api.get(`/themes/`, { params: { course_id: courseId } });
//       return response.data;
//     } catch (error) {
//       if (error.response?.status === 401) {
//         // Попробуйте обновить токен и повторить запрос
//         try {
//           const refreshToken = await AsyncStorage.getItem('refresh_token');
//           const refreshResponse = await api.post('/token/refresh/', { refresh: refreshToken });
//           await AsyncStorage.setItem('access_token', refreshResponse.data.access);

//           // Повторяем запрос с новым токеном
//           const retryResponse = await api.get(`/themes/`, { params: { course_id: courseId } });
//           return retryResponse.data;
//         } catch (refreshError) {
//           // Если обновление токена не удалось, очищаем AsyncStorage и перенаправляем на страницу логина
//           await AsyncStorage.removeItem('access_token');
//           await AsyncStorage.removeItem('refresh_token');
//           window.location.href = '/login';
//           return rejectWithValue(refreshError.response?.data || 'Token refresh failed');
//         }
//       }
//       return rejectWithValue(error.response?.data || 'Failed to fetch themes');
//     }
//   }
// );

// export const createTheme = createAsyncThunk(
//   'texts/createTheme',
//   async ({ name, course_id }, { rejectWithValue }) => {
//     try {
//       const response = await api.post(`/themes/create/`, { name: name, course_id: course_id });
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// export const deleteTheme = createAsyncThunk(
//   'texts/deleteTheme',
//   async (themeId, { rejectWithValue }) => {
//     try {
//       await api.delete(`/themes/delete/${themeId}/`);
//       return themeId;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// // Экшены для работы с выражениями пользователя
// export const fetchUserExpressions = createAsyncThunk(
//   'texts/fetchUserExpressions',
//   async ({ themeId = null, courseId = null }, { rejectWithValue }) => {
//     try {
//       const response = await api.get('/user-expressions/', {
//         params: { theme_id: themeId, course_id: courseId }
//       });
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// export const addUserExpression = createAsyncThunk(
//   'texts/addExpressionToDictionary',
//   async (expressionData, { rejectWithValue }) => {
//     try {
//       const response = await api.post('/user-expressions/', expressionData);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// export const deleteUserExpression = createAsyncThunk(
//   'texts/deleteUserExpression',
//   async (expressionId, { rejectWithValue }) => {
//     try {
//       await api.delete(`/user-expressions/${expressionId}/`);
//       return expressionId;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// // Экшены для работы с текстами и предложениями
// export const analyzeText = createAsyncThunk(
//   'texts/analyzeText',
//   async (textData, { rejectWithValue }) => {
//     try {
//       const response = await api.post(`/texts/`, textData);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// export const setArticleText = createAsyncThunk(
//   'texts/setArticleText',
//   async (text) => {
//     return text;
//   }
// );

// export const setArticleTextFromWeb = createAsyncThunk(
//   'texts/setArticleTextFromWeb',
//   async (text) => {
//     return text;
//   }
// );

// export const fetchSentences = createAsyncThunk(
//   'texts/fetchSentences',
//   async (articleId, { rejectWithValue }) => {
//     try {
//       const response = await api.get(`/articles/${articleId}/sentences/`);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// // Экшены для работы с курсами
// export const fetchCourses = createAsyncThunk(
//   'texts/fetchCourses',
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await api.get(`/courses/`);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// export const fetchArticles = createAsyncThunk(
//   'texts/fetchArticles',
//   async (params, { rejectWithValue, signal }) => {
//     try {
//       const { courseId, themeId } = params;
//       const response = await api.get('/articles/', {
//         params: { course_id: courseId, theme_id: themeId },
//         signal,
//       });
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// export const addCourse = createAsyncThunk(
//   'texts/addCourse',
//   async (courseData, { rejectWithValue }) => {
//     try {
//       const response = await api.post('/courses/create/', courseData);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// export const deleteCourse = createAsyncThunk(
//   'texts/deleteCourse',
//   async (courseId, { rejectWithValue }) => {
//     try {
//       await api.delete(`/courses/delete/${courseId}/`);
//       return courseId;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// // Экшены для работы с подписками
// export const createCheckoutSession = createAsyncThunk(
//   'subscription/createCheckoutSession',
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await api.post('/subscriptions/create_checkout_session/');
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// export const checkSubscriptionStatus = createAsyncThunk(
//   'subscription/checkStatus',
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await api.get('/subscriptions/get_subscription_details/');
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// export const cancelSubscription = createAsyncThunk(
//   'subscription/cancelSubscription',
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await api.post('/subscriptions/cancel_subscription/');
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// // Экшены для работы со словами
// export const fetchWords = createAsyncThunk(
//   'texts/fetchWords',
//   async (sentenceId, { rejectWithValue }) => {
//     try {
//       const response = await api.get(`/sentences/${sentenceId}/words/`);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// export const fetchArticleWords = createAsyncThunk(
//   'texts/fetchArticleWords',
//   async (articleId, { rejectWithValue }) => {
//     try {
//       const response = await api.get(`/articles/${articleId}/words/`);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// export const createWord = createAsyncThunk(
//   'texts/createWord',
//   async ({ sentenceId, text, translation, audio }, { rejectWithValue }) => {
//     try {
//       const formData = new FormData();
//       formData.append('text', text);
//       formData.append('translation', translation);
//       if (audio) formData.append('audio', audio);
//       formData.append('sentence', sentenceId);

//       const response = await api.post('/words/', formData, {
//         headers: { 'Content-Type': 'multipart/form-data' },
//       });
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// export const updateWord = createAsyncThunk(
//   'texts/updateWord',
//   async ({ wordId, text, translation, audio }, { rejectWithValue }) => {
//     try {
//       const formData = new FormData();
//       formData.append('text', text);
//       formData.append('translation', translation);
//       if (audio) formData.append('audio', audio);

//       const response = await api.put(`/words/${wordId}/`, formData, {
//         headers: { 'Content-Type': 'multipart/form-data' },
//       });
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// export const deleteWord = createAsyncThunk(
//   'texts/deleteWord',
//   async (wordId, { rejectWithValue }) => {
//     try {
//       await api.delete(`/words/${wordId}/`);
//       return wordId;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// // Экшены для работы с переводами предложений
// export const updateSentenceTranslation = createAsyncThunk(
//   'texts/updateSentenceTranslation',
//   async ({ id, translation }, { rejectWithValue }) => {
//     try {
//       const response = await api.put(`/sentences/${id}/update-translation/`, { translation });
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// // Экшены для работы с паролями
// export const requestPasswordReset = createAsyncThunk(
//   'texts/requestPasswordReset',
//   async ({ email }, { rejectWithValue }) => {
//     try {
//       const response = await api.post(`/password-reset-request/`, { email });
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// export const resetPassword = createAsyncThunk(
//   'texts/resetPassword',
//   async ({ token, password }, { rejectWithValue }) => {
//     try {
//       const response = await api.post(`/reset-password/${token}/`, { password });
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// // Экшены для работы с группированными словами
// export const fetchGroupedWords = createAsyncThunk(
//   'texts/fetchGroupedWords',
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await api.get('/grouped-words/');
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// // Асинхронный экшен для загрузки фраз по теме с пагинацией
// export const fetchExpressionsByTheme = createAsyncThunk(
//   'texts/fetchExpressionsByTheme',
//   async ({ themeId, page }, { rejectWithValue }) => {
//     try {
//       const response = await api.get(`/user-expressions/by-theme/?theme=${themeId}&page=${page}`);
//       return {
//         themeId,
//         expressions: response.data.results, // Данные для текущей страницы
//         count: response.data.count, // Общее количество фраз
//       };
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
// );

// export const fetchExpressionsCountByTheme = createAsyncThunk(
//   'texts/fetchExpressionsCountByTheme',
//   async (courseId, { rejectWithValue }) => {
//     try {
//       const response = await api.get(`/user-expressions/count-by-theme/?course_id=${courseId}`);
//       return response.data; // Данные в формате { themeId: count, ... }
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
// );

// // Начальное состояние
// const initialState = {
//   themes: [],
//   userExpressions: [],
//   sentences: [],
//   courses: [],
//   articles: [],
//   words: [],
//   articleWords: [], // Новое поле для хранения слов из статьи
//   groupedWords: {},
//   loading: false,
//   error: null,
//   textLanguage: 'de-DE',
//   translationLanguage: 'ru-RU',
//   textVoice: null,
//   translationVoice: null,
//   articleText: '',
//   courseId: null,
//   selectedCourseName: null,
//   subscriptionStatus: 'none',
//   subscriptionError: null,
//   currentPeriodEnd: null,
//   expressionsCountByTheme: {},
// };

// // Создание слайса
// const textsSlice = createSlice({
//   name: 'texts',
//   initialState,
//   reducers: {
//     clearWords(state) {
//       state.words = [];
//     },
//     setTextLanguage: (state, action) => {
//       state.textLanguage = action.payload;
//     },
//     setTranslationLanguage: (state, action) => {
//       state.translationLanguage = action.payload;
//     },
//     setTextVoice: (state, action) => {
//       state.textVoice = action.payload;
//     },
//     setTranslationVoice: (state, action) => {
//       state.translationVoice = action.payload;
//     },
//     setCourseId: (state, action) => {
//       state.courseId = action.payload;
//     },
//     setSelectedCourseName: (state, action) => {
//       state.selectedCourseName = action.payload;
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(fetchThemes.fulfilled, (state, action) => {
//         state.themes = action.payload;
//       })
//       .addCase(createTheme.fulfilled, (state, action) => {
//         state.themes.push(action.payload);
//       })
//       .addCase(deleteTheme.fulfilled, (state, action) => {
//         state.themes = state.themes.filter(theme => theme.id !== action.payload);
//       })
//       .addCase(fetchUserExpressions.fulfilled, (state, action) => {
//         state.userExpressions = action.payload;
//       })
//       .addCase(addUserExpression.fulfilled, (state, action) => {
//         state.userExpressions.push(action.payload);
//       })
//       .addCase(deleteUserExpression.fulfilled, (state, action) => {
//         state.userExpressions = state.userExpressions.filter(expression => expression.id !== action.payload);
//       })
//       .addCase(analyzeText.fulfilled, (state, action) => {
//         // Обработка успешного анализа текста
//       })
//       .addCase(setArticleText.fulfilled, (state, action) => {
//         state.articleText = action.payload;
//       })
//       .addCase(setArticleTextFromWeb.fulfilled, (state, action) => {
//         state.articleText = action.payload;
//       })
//       .addCase(fetchSentences.fulfilled, (state, action) => {
//         state.sentences = action.payload;
//       })
//       .addCase(updateSentenceTranslation.fulfilled, (state, action) => {
//         const updatedSentence = action.payload;
//         const index = state.sentences.findIndex(sentence => sentence.id === updatedSentence.id);
//         if (index !== -1) {
//           state.sentences[index] = updatedSentence;
//         }
//       })
//       .addCase(fetchCourses.fulfilled, (state, action) => {
//         state.themes = [];
//         state.articles = [];
//         state.sentences = [];
//         state.words = [];
//         state.courses = action.payload;
//       })
//       .addCase(fetchArticles.fulfilled, (state, action) => {
//         state.sentences = [];
//         state.words = [];
//         state.articles = action.payload.reverse();
//       })
//       .addCase(addCourse.fulfilled, (state, action) => {
//         state.courses.push(action.payload);
//       })
//       .addCase(deleteCourse.fulfilled, (state, action) => {
//         state.courses = state.courses.filter(course => course.id !== action.payload);
//       })
//       .addCase(createCheckoutSession.fulfilled, (state, action) => {
//         state.subscriptionError = null;
//       })
//       .addCase(createCheckoutSession.rejected, (state, action) => {
//         state.subscriptionError = action.payload.detail || 'An error occurred';
//       })
//       .addCase(checkSubscriptionStatus.fulfilled, (state, action) => {
//         state.subscriptionStatus = action.payload.status;
//         state.currentPeriodEnd = action.payload.current_period_end;
//         state.subscriptionError = null;
//       })
//       .addCase(checkSubscriptionStatus.rejected, (state, action) => {
//         state.subscriptionError = action.payload.detail || 'An error occurred';
//       })
//       .addCase(cancelSubscription.fulfilled, (state) => {
//         state.subscriptionStatus = 'none';
//         state.currentPeriodEnd = null;
//         state.subscriptionError = null;
//       })
//       .addCase(cancelSubscription.rejected, (state, action) => {
//         state.subscriptionError = action.payload.detail || 'An error occurred';
//       })
//       .addCase(fetchWords.fulfilled, (state, action) => {
//         state.words = action.payload;
//       })
//       .addCase(fetchArticleWords.fulfilled, (state, action) => {
//         state.articleWords = action.payload; // Сохраняем слова из статьи
//       })
//       .addCase(createWord.fulfilled, (state, action) => {
//         state.words.push(action.payload);
//       })
//       .addCase(updateWord.fulfilled, (state, action) => {
//         const updatedWord = action.payload;
//         const index = state.words.findIndex((word) => word.id === updatedWord.id);
//         if (index !== -1) {
//           state.words[index] = updatedWord;
//         }
//       })
//       .addCase(deleteWord.fulfilled, (state, action) => {
//         state.words = state.words.filter((word) => word.id !== action.payload);
//       })
//       .addCase(fetchGroupedWords.fulfilled, (state, action) => {
//         state.groupedWords = action.payload;
//       })
//       .addCase(fetchGroupedWords.rejected, (state, action) => {
//         state.error = action.payload;
//       })
//       .addCase(fetchExpressionsCountByTheme.pending, (state) => {
//         state.loading = true;
//         state.error = null;
//       })
//       .addCase(fetchExpressionsCountByTheme.fulfilled, (state, action) => {
//         state.loading = false;
//         state.expressionsCountByTheme = action.payload; // Сохраняем количество выражений для каждой темы
//       })
//       .addCase(fetchExpressionsCountByTheme.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload;
//       })
//       // Обработка состояния "pending" (загрузка началась)
//       .addCase(fetchExpressionsByTheme.pending, (state) => {
//         state.loading = true;
//         state.error = null;
//       })
//       .addCase(fetchExpressionsByTheme.fulfilled, (state, action) => {
//         state.loading = false;
//         state.userExpressions = action.payload.expressions; // Данные для текущей страницы
//       })
//       .addCase(fetchExpressionsByTheme.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload;
//       });
//   },
// });

// export const {
//   clearWords,
//   setTextLanguage,
//   setTranslationLanguage,
//   setTextVoice,
//   setTranslationVoice,
//   setCourseId,
//   setSelectedCourseName,
// } = textsSlice.actions;

// export default textsSlice.reducer;





import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../axios';

// Функция для обновления токена
const refreshAccessToken = async () => {
  try {
    const refreshToken = await AsyncStorage.getItem('refresh_token');
    const response = await api.post('/token/refresh/', { refresh: refreshToken });
    await AsyncStorage.setItem('access_token', response.data.access);
    return response.data.access;
  } catch (error) {
    await AsyncStorage.removeItem('access_token');
    await AsyncStorage.removeItem('refresh_token');
    window.location.href = '/login';
    throw error;
  }
};

// Общая функция для выполнения запросов с обработкой 401
const makeRequest = async (requestFn, args) => {
  try {
    let accessToken = await AsyncStorage.getItem('access_token');
    if (!accessToken) {
      throw new Error('Access token is missing');
    }

    // Добавляем токен в заголовки
    api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

    // Выполняем запрос
    return await requestFn(...args);
  } catch (error) {
    if (error.response?.status === 401) {
      try {
        // Обновляем токен
        const newAccessToken = await refreshAccessToken();
        // Повторяем запрос с новым токеном
        api.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
        return await requestFn(...args);
      } catch (refreshError) {
        throw refreshError;
      }
    }
    throw error;
  }
};

// Экшены для работы с темами
export const fetchThemes = createAsyncThunk(
  'texts/fetchThemes',
  async (courseId, { rejectWithValue }) => {
    try {
      const response = await makeRequest(api.get, [`/themes/`, { params: { course_id: courseId } }]);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch themes');
    }
  }
);

export const createTheme = createAsyncThunk(
  'texts/createTheme',
  async ({ name, course_id }, { rejectWithValue }) => {
    try {
      const response = await makeRequest(api.post, [`/themes/create/`, { name, course_id }]);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to create theme');
    }
  }
);

export const deleteTheme = createAsyncThunk(
  'texts/deleteTheme',
  async (themeId, { rejectWithValue }) => {
    try {
      await makeRequest(api.delete, [`/themes/delete/${themeId}/`]);
      return themeId;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to delete theme');
    }
  }
);

// Экшены для работы с выражениями пользователя
export const fetchUserExpressions = createAsyncThunk(
  'texts/fetchUserExpressions',
  async ({ themeId = null, courseId = null }, { rejectWithValue }) => {
    try {
      const response = await makeRequest(api.get, ['/user-expressions/', { params: { theme_id: themeId, course_id: courseId } }]);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch user expressions');
    }
  }
);

export const addUserExpression = createAsyncThunk(
  'texts/addExpressionToDictionary',
  async (expressionData, { rejectWithValue }) => {
    try {
      const response = await makeRequest(api.post, ['/user-expressions/', expressionData]);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to add user expression');
    }
  }
);

export const deleteUserExpression = createAsyncThunk(
  'texts/deleteUserExpression',
  async (expressionId, { rejectWithValue }) => {
    try {
      await makeRequest(api.delete, [`/user-expressions/${expressionId}/`]);
      return expressionId;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to delete user expression');
    }
  }
);

// Экшены для работы с текстами и предложениями
export const analyzeText = createAsyncThunk(
  'texts/analyzeText',
  async (textData, { rejectWithValue }) => {
    try {
      const response = await makeRequest(api.post, ['/texts/', textData]);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to analyze text');
    }
  }
);

export const setArticleText = createAsyncThunk(
  'texts/setArticleText',
  async (text) => {
    return text;
  }
);

export const setArticleTextFromWeb = createAsyncThunk(
  'texts/setArticleTextFromWeb',
  async (text) => {
    return text;
  }
);

export const fetchSentences = createAsyncThunk(
  'texts/fetchSentences',
  async (articleId, { rejectWithValue }) => {
    try {
      const response = await makeRequest(api.get, [`/articles/${articleId}/sentences/`]);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch sentences');
    }
  }
);

// Экшены для работы с курсами
export const fetchCourses = createAsyncThunk(
  'texts/fetchCourses',
  async (_, { rejectWithValue }) => {
    try {
      const response = await makeRequest(api.get, ['/courses/']);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch courses');
    }
  }
);

export const fetchArticles = createAsyncThunk(
  'texts/fetchArticles',
  async (params, { rejectWithValue, signal }) => {
    try {
      const { courseId, themeId } = params;
      const response = await makeRequest(api.get, ['/articles/', { params: { course_id: courseId, theme_id: themeId }, signal }]);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch articles');
    }
  }
);

export const addCourse = createAsyncThunk(
  'texts/addCourse',
  async (courseData, { rejectWithValue }) => {
    try {
      const response = await makeRequest(api.post, ['/courses/create/', courseData]);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to add course');
    }
  }
);

export const deleteCourse = createAsyncThunk(
  'texts/deleteCourse',
  async (courseId, { rejectWithValue }) => {
    try {
      await makeRequest(api.delete, [`/courses/delete/${courseId}/`]);
      return courseId;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to delete course');
    }
  }
);

// Экшены для работы с подписками
export const createCheckoutSession = createAsyncThunk(
  'subscription/createCheckoutSession',
  async (_, { rejectWithValue }) => {
    try {
      const response = await makeRequest(api.post, ['/subscriptions/create_checkout_session/']);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to create checkout session');
    }
  }
);

export const checkSubscriptionStatus = createAsyncThunk(
  'subscription/checkStatus',
  async (_, { rejectWithValue }) => {
    try {
      const response = await makeRequest(api.get, ['/subscriptions/get_subscription_details/']);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to check subscription status');
    }
  }
);

export const cancelSubscription = createAsyncThunk(
  'subscription/cancelSubscription',
  async (_, { rejectWithValue }) => {
    try {
      const response = await makeRequest(api.post, ['/subscriptions/cancel_subscription/']);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to cancel subscription');
    }
  }
);

// Экшены для работы со словами
export const fetchWords = createAsyncThunk(
  'texts/fetchWords',
  async (sentenceId, { rejectWithValue }) => {
    try {
      const response = await makeRequest(api.get, [`/sentences/${sentenceId}/words/`]);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch words');
    }
  }
);

export const fetchArticleWords = createAsyncThunk(
  'texts/fetchArticleWords',
  async (articleId, { rejectWithValue }) => {
    try {
      const response = await makeRequest(api.get, [`/articles/${articleId}/words/`]);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch article words');
    }
  }
);

export const createWord = createAsyncThunk(
  'texts/createWord',
  async ({ sentenceId, text, translation, audio }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('text', text);
      formData.append('translation', translation);
      if (audio) formData.append('audio', audio);
      formData.append('sentence', sentenceId);

      const response = await makeRequest(api.post, ['/words/', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }]);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to create word');
    }
  }
);

export const updateWord = createAsyncThunk(
  'texts/updateWord',
  async ({ wordId, text, translation, audio }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('text', text);
      formData.append('translation', translation);
      if (audio) formData.append('audio', audio);

      const response = await makeRequest(api.put, [`/words/${wordId}/`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }]);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to update word');
    }
  }
);

export const deleteWord = createAsyncThunk(
  'texts/deleteWord',
  async (wordId, { rejectWithValue }) => {
    try {
      await makeRequest(api.delete, [`/words/${wordId}/`]);
      return wordId;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to delete word');
    }
  }
);

// Экшены для работы с переводами предложений
export const updateSentenceTranslation = createAsyncThunk(
  'texts/updateSentenceTranslation',
  async ({ id, translation }, { rejectWithValue }) => {
    try {
      const response = await makeRequest(api.put, [`/sentences/${id}/update-translation/`, { translation }]);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to update sentence translation');
    }
  }
);

// Экшены для работы с паролями
export const requestPasswordReset = createAsyncThunk(
  'texts/requestPasswordReset',
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await makeRequest(api.post, ['/password-reset-request/', { email }]);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to request password reset');
    }
  }
);

export const resetPassword = createAsyncThunk(
  'texts/resetPassword',
  async ({ token, password }, { rejectWithValue }) => {
    try {
      const response = await makeRequest(api.post, [`/reset-password/${token}/`, { password }]);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to reset password');
    }
  }
);

// Экшены для работы с группированными словами
export const fetchGroupedWords = createAsyncThunk(
  'texts/fetchGroupedWords',
  async (_, { rejectWithValue }) => {
    try {
      const response = await makeRequest(api.get, ['/grouped-words/']);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch grouped words');
    }
  }
);

// Асинхронный экшен для загрузки фраз по теме с пагинацией
export const fetchExpressionsByTheme = createAsyncThunk(
  'texts/fetchExpressionsByTheme',
  async ({ themeId, page }, { rejectWithValue }) => {
    try {
      const response = await makeRequest(api.get, [`/user-expressions/by-theme/?theme=${themeId}&page=${page}`]);
      return {
        themeId,
        expressions: response.data.results,
        count: response.data.count,
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchExpressionsCountByTheme = createAsyncThunk(
  'texts/fetchExpressionsCountByTheme',
  async (courseId, { rejectWithValue }) => {
    try {
      const response = await makeRequest(api.get, [`/user-expressions/count-by-theme/?course_id=${courseId}`]);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Начальное состояние
const initialState = {
  themes: [],
  userExpressions: [],
  sentences: [],
  courses: [],
  articles: [],
  words: [],
  articleWords: [],
  groupedWords: {},
  loading: false,
  error: null,
  textLanguage: 'de-DE',
  translationLanguage: 'ru-RU',
  textVoice: null,
  translationVoice: null,
  articleText: '',
  courseId: null,
  selectedCourseName: null,
  subscriptionStatus: 'none',
  subscriptionError: null,
  currentPeriodEnd: null,
  expressionsCountByTheme: {},
};

// Создание слайса
const textsSlice = createSlice({
  name: 'texts',
  initialState,
  reducers: {
    clearWords(state) {
      state.words = [];
    },
    setTextLanguage: (state, action) => {
      state.textLanguage = action.payload;
    },
    setTranslationLanguage: (state, action) => {
      state.translationLanguage = action.payload;
    },
    setTextVoice: (state, action) => {
      state.textVoice = action.payload;
    },
    setTranslationVoice: (state, action) => {
      state.translationVoice = action.payload;
    },
    setCourseId: (state, action) => {
      state.courseId = action.payload;
    },
    setSelectedCourseName: (state, action) => {
      state.selectedCourseName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchThemes.fulfilled, (state, action) => {
        state.themes = action.payload;
      })
      .addCase(createTheme.fulfilled, (state, action) => {
        state.themes.push(action.payload);
      })
      .addCase(deleteTheme.fulfilled, (state, action) => {
        state.themes = state.themes.filter(theme => theme.id !== action.payload);
      })
      .addCase(fetchUserExpressions.fulfilled, (state, action) => {
        state.userExpressions = action.payload;
      })
      .addCase(addUserExpression.fulfilled, (state, action) => {
        state.userExpressions.push(action.payload);
      })
      .addCase(deleteUserExpression.fulfilled, (state, action) => {
        state.userExpressions = state.userExpressions.filter(expression => expression.id !== action.payload);
      })
      .addCase(analyzeText.fulfilled, (state, action) => {
        // Обработка успешного анализа текста
      })
      .addCase(setArticleText.fulfilled, (state, action) => {
        state.articleText = action.payload;
      })
      .addCase(setArticleTextFromWeb.fulfilled, (state, action) => {
        state.articleText = action.payload;
      })
      .addCase(fetchSentences.fulfilled, (state, action) => {
        state.sentences = action.payload;
      })
      .addCase(updateSentenceTranslation.fulfilled, (state, action) => {
        const updatedSentence = action.payload;
        const index = state.sentences.findIndex(sentence => sentence.id === updatedSentence.id);
        if (index !== -1) {
          state.sentences[index] = updatedSentence;
        }
      })
      .addCase(fetchCourses.fulfilled, (state, action) => {
        state.themes = [];
        state.articles = [];
        state.sentences = [];
        state.words = [];
        state.courses = action.payload;
      })
      .addCase(fetchArticles.fulfilled, (state, action) => {
        state.sentences = [];
        state.words = [];
        state.articles = action.payload.reverse();
      })
      .addCase(addCourse.fulfilled, (state, action) => {
        state.courses.push(action.payload);
      })
      .addCase(deleteCourse.fulfilled, (state, action) => {
        state.courses = state.courses.filter(course => course.id !== action.payload);
      })
      .addCase(createCheckoutSession.fulfilled, (state, action) => {
        state.subscriptionError = null;
      })
      .addCase(createCheckoutSession.rejected, (state, action) => {
        state.subscriptionError = action.payload.detail || 'An error occurred';
      })
      .addCase(checkSubscriptionStatus.fulfilled, (state, action) => {
        state.subscriptionStatus = action.payload.status;
        state.currentPeriodEnd = action.payload.current_period_end;
        state.subscriptionError = null;
      })
      .addCase(checkSubscriptionStatus.rejected, (state, action) => {
        state.subscriptionError = action.payload.detail || 'An error occurred';
      })
      .addCase(cancelSubscription.fulfilled, (state) => {
        state.subscriptionStatus = 'none';
        state.currentPeriodEnd = null;
        state.subscriptionError = null;
      })
      .addCase(cancelSubscription.rejected, (state, action) => {
        state.subscriptionError = action.payload.detail || 'An error occurred';
      })
      .addCase(fetchWords.fulfilled, (state, action) => {
        state.words = action.payload;
      })
      .addCase(fetchArticleWords.fulfilled, (state, action) => {
        state.articleWords = action.payload;
      })
      .addCase(createWord.fulfilled, (state, action) => {
        state.words.push(action.payload);
      })
      .addCase(updateWord.fulfilled, (state, action) => {
        const updatedWord = action.payload;
        const index = state.words.findIndex((word) => word.id === updatedWord.id);
        if (index !== -1) {
          state.words[index] = updatedWord;
        }
      })
      .addCase(deleteWord.fulfilled, (state, action) => {
        state.words = state.words.filter((word) => word.id !== action.payload);
      })
      .addCase(fetchGroupedWords.fulfilled, (state, action) => {
        state.groupedWords = action.payload;
      })
      .addCase(fetchGroupedWords.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(fetchExpressionsCountByTheme.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchExpressionsCountByTheme.fulfilled, (state, action) => {
        state.loading = false;
        state.expressionsCountByTheme = action.payload;
      })
      .addCase(fetchExpressionsCountByTheme.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchExpressionsByTheme.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchExpressionsByTheme.fulfilled, (state, action) => {
        state.loading = false;
        state.userExpressions = action.payload.expressions;
      })
      .addCase(fetchExpressionsByTheme.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  clearWords,
  setTextLanguage,
  setTranslationLanguage,
  setTextVoice,
  setTranslationVoice,
  setCourseId,
  setSelectedCourseName,
} = textsSlice.actions;

export default textsSlice.reducer;