import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSentences, updateSentenceTranslation } from '../redux/textsSlice';
import { fetchArticleWords } from '../redux/textsSlice';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Button, List, ListItem, ListItemText, Typography, Container, IconButton, Modal, Box, TextField, Paper, Switch, FormControlLabel, CircularProgress, Divider } from '@mui/material';
import { VolumeUp, Visibility, Edit, Book, Lightbulb, ArrowBack, Refresh } from '@mui/icons-material';
import { createSelector } from 'reselect';
import { synthesizeSpeech, clearAudioUrl } from '../redux/ttsSlice'; // Импортируем TTS
import { isTelegramWebApp, transformLang } from './Utils'; // Импортируем transformLang

// Мемоизированный селектор для предложений
const selectSentences = createSelector(
  state => state.texts.sentences,
  state => state.texts.loading,
  state => state.texts.error,
  state => state.auth.user,
  (sentences, loading, error, user) => ({ sentences, loading, error, user })
);

// Мемоизированный селектор для слов
const selectWords = createSelector(
  state => state.texts.articleWords,
  state => state.texts.loading,
  state => state.texts.error,
  (articleWords, loading, error) => ({ articleWords, loading, error })
);

const SentenceList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { audioUrl, isLoading: isTtsLoading, error: ttsError } = useSelector((state) => state.tts); // Состояние TTS
  const audioRef = useRef(null); // Ref для управления аудио
  const [isAudioPlaying, setIsAudioPlaying] = useState(false); // Состояние для отслеживания воспроизведения аудио
  const { sentences, loading, error, user } = useSelector(selectSentences);
  const { articleWords, loading: wordsLoading, error: wordsError } = useSelector(selectWords);
  const [showTranslations, setShowTranslations] = useState({});
  const { articleId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [selectedSentence, setSelectedSentence] = useState(null);
  const [isWordsMode, setIsWordsMode] = useState(false);
  const [isTrainingLoading, setIsTrainingLoading] = useState(false);
  const articleData = location.state;

  const articleText = articleData?.text || '';
  const articleTitle = articleData?.title || '';
  const articleTextLang = articleData?.text_language || '';
  const articleTransLang = articleData?.translation_language || '';

  // Получаем themeMode из Redux
  const themeMode = useSelector((state) => state.theme.theme);

  useEffect(() => {
    dispatch(fetchSentences(articleId));
  }, [dispatch, articleId]);


  // Воспроизведение аудио, если URL доступен
  useEffect(() => {
    if (audioUrl) {
      setIsAudioPlaying(true); // Устанавливаем состояние воспроизведения аудио
      const audio = new Audio(audioUrl);
      audioRef.current = audio; // Сохраняем ссылку на аудио
      audio.play();

      // Обработчик завершения воспроизведения
      audio.addEventListener('ended', () => {
        setIsAudioPlaying(false); // Сбрасываем состояние воспроизведения аудио
        dispatch(clearAudioUrl()); // Очищаем audioUrl
      });
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.pause(); // Останавливаем воспроизведение при размонтировании
        audioRef.current.removeEventListener('ended', () => {}); // Удаляем обработчик
      }
    };
  }, [audioUrl, dispatch]);



  // Функция для воспроизведения аудио предложения
  const handlePlayAudio = (text, lang) => {
    if (!user || !user.text_language) {
      console.error("User data is not available or text_language is missing.");
      return;
    }

    if (isAudioPlaying) {
      console.error("Audio is playng.");
      return;
    }

    const lang_tr = transformLang(user.text_language);

    // Проверяем, запущено ли приложение в Telegram
    if (isTelegramWebApp()) {
      // Используем Redux для синтеза речи
      dispatch(synthesizeSpeech({ text: text, lang: lang_tr }));
    } else if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'speak', text, lang: lang_tr, comp: 'SentenceList', voice: user.text_voice }));
    } else {
      const msg = new SpeechSynthesisUtterance(text);
      msg.lang = lang_tr;
      window.speechSynthesis.speak(msg);
    }
  };

  const handleModeChange = (event) => {
    setIsWordsMode(event.target.checked);
  };

  const handleSuggestiveTraining = async () => {
    setIsTrainingLoading(true);

    if (isWordsMode) {
      await dispatch(fetchArticleWords(articleId)).unwrap();
      navigate('/suggestive-training', { state: { words: articleWords, isWordsMode: true } });
    } else {
      navigate('/suggestive-training', { state: { sentences, isWordsMode: false } });
    }

    setIsTrainingLoading(false);
  };

  const handleRefresh = () => {
    dispatch(fetchSentences(articleId));
  };

  const toggleTranslation = (sentenceId) => {
    setShowTranslations({
      ...showTranslations,
      [sentenceId]: !showTranslations[sentenceId],
    });
  };

  const handleShowDictionary = (sentId, sentText) => {
    const sentenceData = {
      id: sentId,
      text: sentText,
    };

    navigate(`/dictionary/${sentId}`, { state: sentenceData });
  };

  const handleSaveTranslation = () => {
    if (selectedSentence) {
      dispatch(updateSentenceTranslation({
        id: selectedSentence.id,
        translation: selectedSentence.translation,
      })).then(() => {
        setShowModal(false);
      });
    }
  };

  const handleOpenTextReader = () => {
    navigate(`/text-reader/${articleId}`, {
      state: {
        articleText,
        articleTitle,
        articleTextLang,
        user,
      },
    });
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">Error: {error}</Typography>;

  const filteredSentences = sentences
    .filter(sentence => sentence.article_id === parseInt(articleId))
    .sort((a, b) => a.order - b.order);

  return (
    <Container sx={{ padding: 0, backgroundColor: themeMode === "dark" ? "#121212" : "#ffffff", color: themeMode === "dark" ? "#e0e0e0" : "#000000" }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h5" sx={{ ml: 2 }}>
          {articleTitle}
        </Typography>
      </Box>

      <Box mt={2} mb={2}>
        <Button
          variant="outlined"
          onClick={handleOpenTextReader}
          startIcon={<Book />}
        >
          Open Text Reader
        </Button>
        <Button variant="outlined" onClick={handleRefresh} startIcon={<Refresh />} sx={{ ml: 2 }}>
          Refresh
        </Button>

      </Box>

      <Box mt={2} mb={2}>
        <FormControlLabel
          control={<Switch checked={isWordsMode} onChange={handleModeChange} />}
          label="Train by words"
        />
        <Button
          variant="outlined"
          onClick={handleSuggestiveTraining}
          startIcon={<Lightbulb />}
          disabled={isTrainingLoading}
          sx={{ ml: 0 }}
        >
          {isTrainingLoading ? <CircularProgress size={24} /> : 'Training'}
        </Button>
      </Box>


      <List>
        {filteredSentences.map(sentence => (
          <Paper
            key={sentence.id}
            sx={{
              mb: 1,
              p: 2,
              backgroundColor: themeMode === "dark" ? "#1e1e1e" : "#ffffff",
              color: themeMode === "dark" ? "#e0e0e0" : "#000000",
              boxShadow: themeMode === "dark" ? "0px 2px 4px rgba(0, 0, 0, 0.5)" : "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <ListItem sx={{ flexDirection: 'column', alignItems: 'flex-start', p: 0 }}>
              <ListItemText
                primary={sentence.text}
                secondary={showTranslations[sentence.id] ? sentence.translation : null}
                sx={{ color: themeMode === "dark" ? "#e0e0e0" : "#000000" }}
              />
              <Divider sx={{ width: '100%', my: 1 }} />
              <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                <IconButton onClick={() => handlePlayAudio(sentence.text, articleTextLang)} size="small" sx={{ padding: '4px' }}>
                  <VolumeUp fontSize="small" color="primary" />
                </IconButton>
                <IconButton onClick={() => toggleTranslation(sentence.id)} size="small" sx={{ padding: '4px' }}>
                  <Visibility fontSize="small" color="secondary" />
                </IconButton>
                <IconButton onClick={() => { setSelectedSentence(sentence); setShowModal(true); }} size="small" sx={{ padding: '4px' }}>
                  <Edit fontSize="small" color="warning" />
                </IconButton>
                <IconButton onClick={() => handleShowDictionary(sentence.id, sentence.text)} size="small" sx={{ padding: '4px' }}>
                  <Book fontSize="small" color="info" />
                </IconButton>
              </Box>
            </ListItem>
          </Paper>
        ))}
      </List>

      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          <Typography variant="h6" mb={2}>Edit Translation</Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={selectedSentence?.translation || ''}
            onChange={(e) => setSelectedSentence({ ...selectedSentence, translation: e.target.value })}
          />
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button onClick={() => setShowModal(false)}>Cancel</Button>
            <Button variant="contained" onClick={handleSaveTranslation} sx={{ ml: 2 }}>Save</Button>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};

export default SentenceList;