// import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import { GoogleOAuthProvider } from '@react-oauth/google';
// import { Provider } from 'react-redux';
// import { PersistGate } from 'redux-persist/integration/react';
// import store, { persistor } from './redux/store';
// import { ThemeProvider, CssBaseline, Container } from '@mui/material';
// import Navbar from './components/Navbar';
// import TextAnalyzer from './components/TextAnalyzer';
// import SentenceList from './components/SentenceList';
// import Register from './components/Register';
// import Login from './components/Login';
// import VerifyEmail from './components/VerifyEmail';
// import Dictionary from './components/Dictionary';
// import UserExpressionsList from './components/UserExpressionsList';
// import PasswordResetRequest from './components/PasswordResetRequest';
// import PasswordReset from './components/PasswordReset';
// import UserProfile from './components/UserProfile';
// import HomePage from './components/HomePage';
// import ManageCoursesPage from './components/ManageCoursesPage';
// import AddTextPage from './components/AddTextPage';
// import SubscriptionStatus from './components/SubscriptionStatus';
// import SubscriptionButton from './components/SubscriptionButton';
// import SuggestiveTrainingPage from './components/SuggestiveTrainingPage';
// import FlashcardsPage from './components/FlashcardsPage';
// import FullDictionary from './components/FullDictionary';
// import ThemeManager from './components/ThemeManager';
// import BottomNav from './components/BottomNav'; // Импортируем BottomNav
// import { lightTheme, darkTheme } from './theme';
// import 'bootstrap-icons/font/bootstrap-icons.css';
// import './quillSetup';
// import './App.css';

// function App() {
//   const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
//   const theme = useSelector((state) => state.theme.theme);

//   useEffect(() => {
//     document.body.className = theme;
//   }, [theme]);

//   return (
//     <GoogleOAuthProvider clientId="123.apps.googleusercontent.com">
//       <Router>
//         <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
//           <CssBaseline />
//           {isAuthenticated && <Navbar />}
//           <Container maxWidth="lg" sx={{ paddingTop: 4, paddingBottom: 10 }}>
//             <Routes>
//               <Route path="/register" element={<Register />} />
//               <Route path="/login" element={<Login />} />
//               <Route path="/verify-email/:token" element={<VerifyEmail />} />
//               <Route
//                 path="/"
//                 element={
//                   isAuthenticated ? (
//                     <HomePage />
//                   ) : (
//                     <Navigate to="/login" replace />
//                   )
//                 }
//               />
//               <Route path="/manage-courses" element={<ManageCoursesPage />} />
//               <Route path="/articles/:articleId" element={<SentenceList />} />
//               <Route path="/dictionary/:sentenceId" element={<Dictionary />} />
//               <Route path="/user-expressions" element={<UserExpressionsList />} />
//               <Route path="/password-reset-request" element={<PasswordResetRequest />} />
//               <Route path="/reset-password/:token" element={<PasswordReset />} />
//               <Route path="/profile" element={<UserProfile />} />
//               <Route path="/text-analyzer/:courseId" element={<TextAnalyzer />} />
//               <Route path="/add-text" element={<AddTextPage />} />
//               <Route path="/subscription-status" element={<SubscriptionStatus />} />
//               <Route path="/subscribe" element={<SubscriptionButton />} />
//               <Route path="/suggestive-training" element={<SuggestiveTrainingPage />} />
//               <Route path="/flashcards" element={<FlashcardsPage />} />
//               <Route path="/full-dictionary" element={<FullDictionary />} />
//               <Route path="/theme-manager" element={<ThemeManager />} />
//             </Routes>
//           </Container>
//           {isAuthenticated && <BottomNav />} {/* Используем BottomNav внутри Router */}
//         </ThemeProvider>
//       </Router>
//     </GoogleOAuthProvider>
//   );
// }

// function Root() {
//   return (
//     <Provider store={store}>
//       <PersistGate loading={null} persistor={persistor}>
//         <App />
//       </PersistGate>
//     </Provider>
//   );
// }

// export default Root;




// import React, { useEffect } from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';
// import { GoogleOAuthProvider } from '@react-oauth/google';
// import { Provider } from 'react-redux';
// import { PersistGate } from 'redux-persist/integration/react';
// import store, { persistor } from './redux/store';
// import { ThemeProvider, CssBaseline, Container } from '@mui/material';
// import Navbar from './components/Navbar';
// import TextAnalyzer from './components/TextAnalyzer';
// import SentenceList from './components/SentenceList';
// import Register from './components/Register';
// import Login from './components/Login';
// import VerifyEmail from './components/VerifyEmail';
// import Dictionary from './components/Dictionary';
// import UserExpressionsList from './components/UserExpressionsList';
// import PasswordResetRequest from './components/PasswordResetRequest';
// import PasswordReset from './components/PasswordReset';
// import UserProfile from './components/UserProfile';
// import HomePage from './components/HomePage';
// import ManageCoursesPage from './components/ManageCoursesPage';
// import AddTextPage from './components/AddTextPage';
// import SubscriptionStatus from './components/SubscriptionStatus';
// import SubscriptionButton from './components/SubscriptionButton';
// import SuggestiveTrainingPage from './components/SuggestiveTrainingPage';
// import FlashcardsPage from './components/FlashcardsPage';
// import FullDictionary from './components/FullDictionary';
// import ThemeManager from './components/ThemeManager';
// import BottomNav from './components/BottomNav';
// import CrosswordTrainingPage from './components/CrosswordTrainingPage';
// import { lightTheme, darkTheme } from './theme';
// import { loadThemeSettings } from './redux/themeSlice';
// import 'bootstrap-icons/font/bootstrap-icons.css';
// import './quillSetup';
// import './App.css';

// function App() {
//   const dispatch = useDispatch();
//   const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
//   const theme = useSelector((state) => state.theme.theme);
//   const themeColor = useSelector((state) => state.theme.color);

//   useEffect(() => {
//     // Инициализация Telegram Web App
//     const tg = window.Telegram.WebApp;

//     // Развернуть приложение на весь экран
//     tg.expand();

//     // Изменить цвет кнопки "Назад" в Telegram
//     tg.setHeaderColor('#ffffff');

//     // Обработка события закрытия приложения
//     tg.onEvent('mainButtonClicked', () => {
//       tg.close();
//     });

//     // Показать кнопку "Назад"
//     tg.MainButton.setText('Закрыть').show();

//     // Очистка при размонтировании компонента
//     return () => {
//       tg.offEvent('mainButtonClicked');
//     };
//   }, []);

//   // Загрузка настроек темы и цветовой схемы при запуске приложения
//   useEffect(() => {
//     dispatch(loadThemeSettings());
//   }, [dispatch]);

//   // Применение CSS-переменных глобально
//   useEffect(() => {
//     document.documentElement.style.setProperty('--primary-color', themeColor);
//     document.documentElement.style.setProperty('--background-color', theme === 'dark' ? '#121212' : '#ffffff');
//     document.documentElement.style.setProperty('--text-color', theme === 'dark' ? '#e0e0e0' : '#000000');
//   }, [theme, themeColor]);

//   return (
//     <GoogleOAuthProvider clientId="123.apps.googleusercontent.com">
//       <Router>
//         <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
//           <CssBaseline />
//           {isAuthenticated && <Navbar />}
//           <Container maxWidth="lg" sx={{ paddingTop: 4, paddingBottom: 10 }}>
//             <Routes>
//               <Route path="/register" element={<Register />} />
//               <Route path="/login" element={<Login />} />
//               <Route path="/email-verify/:token" element={<VerifyEmail />} />
//               <Route
//                 path="/"
//                 element={
//                   isAuthenticated ? (
//                     <HomePage />
//                   ) : (
//                     <Navigate to="/login" replace />
//                   )
//                 }
//               />
//               <Route path="/manage-courses" element={<ManageCoursesPage />} />
//               <Route path="/articles/:articleId" element={<SentenceList />} />
//               <Route path="/dictionary/:sentenceId" element={<Dictionary />} />
//               <Route path="/user-expressions" element={<UserExpressionsList />} />
//               <Route path="/password-reset-request" element={<PasswordResetRequest />} />
//               <Route path="/reset-password/:token" element={<PasswordReset />} />
//               <Route path="/profile" element={<UserProfile />} />
//               <Route path="/text-analyzer/:courseId" element={<TextAnalyzer />} />
//               <Route path="/add-text" element={<AddTextPage />} />
//               <Route path="/subscription-status" element={<SubscriptionStatus />} />
//               <Route path="/subscribe" element={<SubscriptionButton />} />
//               <Route path="/suggestive-training" element={<SuggestiveTrainingPage />} />
//               <Route path="/flashcards" element={<FlashcardsPage />} />
//               <Route path="/full-dictionary" element={<FullDictionary />} />
//               <Route path="/theme-manager" element={<ThemeManager />} />
//               <Route path="/crossword" element={<CrosswordTrainingPage />} />
//             </Routes>
//           </Container>
//           {isAuthenticated && <BottomNav />}
//         </ThemeProvider>
//       </Router>
//     </GoogleOAuthProvider>
//   );
// }

// function Root() {
//   return (
//     <Provider store={store}>
//       <PersistGate loading={null} persistor={persistor}>
//         <App />
//       </PersistGate>
//     </Provider>
//   );
// }

// export default Root;



import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './redux/store';
import { ThemeProvider, CssBaseline, Container } from '@mui/material';
import TextAnalyzer from './components/TextAnalyzer';
import SentenceList from './components/SentenceList';
import Register from './components/Register';
import Login from './components/Login';
import VerifyEmail from './components/VerifyEmail';
import Dictionary from './components/Dictionary';
import UserExpressionsList from './components/UserExpressionsList';
import PasswordResetRequest from './components/PasswordResetRequest';
import PasswordReset from './components/PasswordReset';
import UserProfile from './components/UserProfile';
import HomePage from './components/HomePage';
import ManageCoursesPage from './components/ManageCoursesPage';
import AddTextPage from './components/AddTextPage';
import SubscriptionStatus from './components/SubscriptionStatus';
import SubscriptionButton from './components/SubscriptionButton';
import SuggestiveTrainingPage from './components/SuggestiveTrainingPage';
import FlashcardsPage from './components/FlashcardsPage';
import FullDictionary from './components/FullDictionary';
import ThemeManager from './components/ThemeManager';
import BottomNav from './components/BottomNav';
import CrosswordTrainingPage from './components/CrosswordTrainingPage';
import TextReader from './components/TextReader';
import { lightTheme, darkTheme } from './theme';
import { loadThemeSettings } from './redux/themeSlice';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './quillSetup';
import './App.css';

function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const theme = useSelector((state) => state.theme.theme);
  const themeColor = useSelector((state) => state.theme.color);
  const isTelegramWebView = /Telegram|TDesktop/i.test(navigator.userAgent);


  // Инициализация Telegram WebApp
  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp && isTelegramWebView) {
      const tg = window.Telegram.WebApp;
      tg.expand(); // Развернуть приложение на весь экран
      tg.setHeaderColor('#ffffff'); // Установить цвет заголовка
      //tg.MainButton.setText('Закрыть').show(); // Показать кнопку "Закрыть"
    
      tg.onEvent('mainButtonClicked', () => {
        tg.close(); // Закрыть приложение при нажатии на кнопку
      });
    
      return () => {
        tg.offEvent('mainButtonClicked');
      };
    }
  }, []);

  // Загрузка настроек темы и цветовой схемы при запуске приложения
  useEffect(() => {
    dispatch(loadThemeSettings());
  }, [dispatch]);

  // Применение CSS-переменных глобально
  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', themeColor);
    document.documentElement.style.setProperty('--background-color', theme === 'dark' ? '#121212' : '#ffffff');
    document.documentElement.style.setProperty('--text-color', theme === 'dark' ? '#e0e0e0' : '#000000');
  }, [theme, themeColor]);

  // Отправка сообщения в Telegram WebApp
  const sendMessageToTelegram = (message) => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.sendData(JSON.stringify(message));
    } else {
      console.error('Telegram WebApp API недоступен.');
    }
  };


  return (
    <GoogleOAuthProvider clientId="123.apps.googleusercontent.com">
      <Router>
        <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
          <CssBaseline />
          {isAuthenticated }
          <Container maxWidth="lg" sx={{ paddingTop: 4, paddingBottom: 10 }}>
            <Routes>
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/email-verify/:token" element={<VerifyEmail />} />
              <Route
                path="/"
                element={
                  isAuthenticated ? (
                    <HomePage />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route path="/manage-courses" element={<ManageCoursesPage />} />
              <Route path="/articles/:articleId" element={<SentenceList />} />
              <Route path="/dictionary/:sentenceId" element={<Dictionary />} />
              <Route path="/user-expressions" element={<UserExpressionsList />} />
              <Route path="/password-reset-request" element={<PasswordResetRequest />} />
              <Route path="/reset-password/:token" element={<PasswordReset />} />
              <Route path="/profile" element={<UserProfile />} />
              <Route path="/text-analyzer/:courseId" element={<TextAnalyzer />} />
              <Route path="/add-text" element={<AddTextPage />} />
              <Route path="/subscription-status" element={<SubscriptionStatus />} />
              <Route path="/subscribe" element={<SubscriptionButton />} />
              <Route path="/suggestive-training" element={<SuggestiveTrainingPage />} />
              <Route path="/flashcards" element={<FlashcardsPage />} />
              <Route path="/full-dictionary" element={<FullDictionary />} />
              <Route path="/theme-manager" element={<ThemeManager />} />
              <Route path="/crossword" element={<CrosswordTrainingPage />} />
              <Route path="/text-reader/:articleId" element={<TextReader />} />
            </Routes>
          </Container>
          {isAuthenticated && <BottomNav />}
        </ThemeProvider>
      </Router>
    </GoogleOAuthProvider>
  );
}

function Root() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  );
}

export default Root;