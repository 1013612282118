
// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import AsyncStorage from '@react-native-async-storage/async-storage';
// import {
//   setTextLanguage,
//   setTranslationLanguage,
//   setTextVoice,
//   setTranslationVoice,
//   checkSubscriptionStatus,
//   cancelSubscription,
// } from '../redux/textsSlice';
// import { fetchUserProfile, saveUserSettings } from '../redux/authSlice';
// import { toggleTheme, setThemeColor, saveThemeSettings } from '../redux/themeSlice';
// import SubscriptionButton from './SubscriptionButton';
// import SubscriptionStatus from './SubscriptionStatus';
// import {
//   Box,
//   Typography,
//   Button,
//   MenuItem,
//   FormControlLabel,
//   CircularProgress,
//   TextField,
//   InputAdornment,
//   Switch,
// } from '@mui/material';
// import { ArrowBack, Language, RecordVoiceOver, Palette } from '@mui/icons-material';

// const UserProfile = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const user = useSelector((state) => state.auth.user);
//   const textLanguage = useSelector((state) => state.texts.textLanguage);
//   const translationLanguage = useSelector((state) => state.texts.translationLanguage);
//   const textVoice = useSelector((state) => state.texts.textVoice);
//   const translationVoice = useSelector((state) => state.texts.translationVoice);
//   const subscriptionStatus = useSelector((state) => state.texts.subscriptionStatus);
//   const theme = useSelector((state) => state.theme.theme);
//   const themeColor = useSelector((state) => state.theme.color);

//   const [isWebView, setIsWebView] = useState(false);
//   const [textVoices, setTextVoices] = useState([]);
//   const [translationVoices, setTranslationVoices] = useState([]);
//   const [loadingVoices, setLoadingVoices] = useState(true);

//   const colorSchemes = [
//     { name: 'Gray', value: '#6c757d' },
//     { name: 'Turquoise', value: '#00ced1' }, // Бирюзовый
//     { name: 'Blue', value: '#03a9f4' }, // Голубой
//     { name: 'Orange', value: '#ff8c42' }, // Оранжевый
//     { name: 'Emerald', value: '#2ecc71' }, // Изумрудный
//     { name: 'Purple', value: '#9b59b6' }, // Пурпурный
//     { name: 'Pink', value: '#e84393' }, // Розовый
//     { name: 'Gold', value: '#f1c40f' }, // Золотой
//     { name: 'Coral', value: '#ff6f61' }, // Коралловый
//     { name: 'Light Green', value: '#55efc4' }, // Светло-зеленый
//     { name: 'Deep Blue', value: '#3498db' }, // Глубокий синий
//     { name: 'Red', value: '#ff4757' }, // Красный
//   ];

//   // Загрузка профиля пользователя и статуса подписки при монтировании компонента
//   useEffect(() => {
//     dispatch(fetchUserProfile());
//     dispatch(checkSubscriptionStatus());
//     setIsWebView(!!window.ReactNativeWebView);
//   }, [dispatch]);

//   // Загрузка голосов для текста и перевода
//   useEffect(() => {
//     setLoadingVoices(true);

//     const loadVoices = () => {
//       if (window.speechSynthesis) {
//         const synth = window.speechSynthesis;
//         const availableVoices = synth.getVoices();
//         setTextVoices(availableVoices.filter((voice) => voice.lang.startsWith(textLanguage.split('-')[0])));
//         setTranslationVoices(availableVoices.filter((voice) => voice.lang.startsWith(translationLanguage.split('-')[0])));
//         setLoadingVoices(false);
//       } else {
//         console.error('SpeechSynthesis is not supported in this browser.');
//         setLoadingVoices(false);
//       }
//     };

//     if (isWebView) {
//       window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'GET_VOICES', lang: textLanguage, target: 'text', comp: 'UserProfile' }));
//       window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'GET_VOICES', lang: translationLanguage, target: 'translation', comp: 'UserProfile' }));
//     } else {
//       if (window.speechSynthesis) {
//         window.speechSynthesis.onvoiceschanged = loadVoices;
//       }
//       loadVoices();
//     }

//     const handleMessage = (event) => {
//       try {
//         const data = JSON.parse(event.data);
//         if (data.type === 'VOICES_RESPONSE') {
//           if (data.target === 'text') {
//             setTextVoices(data.voices);
//           } else if (data.target === 'translation') {
//             setTranslationVoices(data.voices);
//           }
//           setLoadingVoices(false);
//         }
//       } catch (error) {
//         console.error('Invalid JSON:', event.data);
//       }
//     };

//     document.addEventListener('message', handleMessage);
//     return () => {
//       document.removeEventListener('message', handleMessage);
//     };
//   }, [textLanguage, translationLanguage, isWebView]);

//   // Установка голосов по умолчанию
//   useEffect(() => {
//     if (textVoices.length > 0 && !textVoice) {
//       dispatch(setTextVoice(textVoices[0].name));
//     }
//   }, [textVoices, textVoice, dispatch]);

//   useEffect(() => {
//     if (translationVoices.length > 0 && !translationVoice) {
//       dispatch(setTranslationVoice(translationVoices[0].name));
//     }
//   }, [translationVoices, translationVoice, dispatch]);

//   // Обработчики изменения языка текста и перевода
//   const handleTextLanguageChange = (e) => {
//     const language = e.target.value;
//     dispatch(setTextLanguage(language));
//     dispatch(saveUserSettings({ text_language: language }));
//     if (isWebView) {
//       window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'GET_VOICES', lang: language, target: 'text', comp: 'UserProfile' }));
//     } else {
//       const synth = window.speechSynthesis;
//       const availableVoices = synth.getVoices();
//       setTextVoices(availableVoices.filter((voice) => voice.lang.startsWith(language.split('-')[0])));
//     }
//   };

//   const handleTranslationLanguageChange = (e) => {
//     const language = e.target.value;
//     dispatch(setTranslationLanguage(language));
//     dispatch(saveUserSettings({ translation_language: language }));
//     if (isWebView) {
//       window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'GET_VOICES', lang: language, target: 'translation', comp: 'UserProfile' }));
//     } else {
//       const synth = window.speechSynthesis;
//       const availableVoices = synth.getVoices();
//       setTranslationVoices(availableVoices.filter((voice) => voice.lang.startsWith(language.split('-')[0])));
//     }
//   };

//   // Обработчики изменения голосов
//   const handleTextVoiceChange = (e) => {
//     const voice = e.target.value;
//     dispatch(setTextVoice(voice));
//     dispatch(saveUserSettings({ text_voice: voice }));
//   };

//   const handleTranslationVoiceChange = (e) => {
//     const voice = e.target.value;
//     dispatch(setTranslationVoice(voice));
//     dispatch(saveUserSettings({ translation_voice: voice }));
//   };

//   // Обработчик отмены подписки
//   const handleCancelSubscription = async () => {
//     try {
//       await dispatch(cancelSubscription()).unwrap();
//       toast.success('Subscription canceled successfully.');
//     } catch (error) {
//       console.error('Failed to cancel subscription:', error);
//       toast.error('Failed to cancel subscription.');
//     }
//   };

//   // Обработчик переключения темы
//   const handleToggleTheme = async () => {
//     const newTheme = theme === 'dark' ? 'light' : 'dark';
//     dispatch(toggleTheme());

//     // Сохраняем настройки темы
//     dispatch(saveThemeSettings({ theme: newTheme, color: themeColor }));
//   };

//   // Обработчик изменения цветовой схемы
//   const handleThemeColorChange = async (e) => {
//     const color = e.target.value;
//     dispatch(setThemeColor(color));

//     // Сохраняем настройки цветовой схемы
//     dispatch(saveThemeSettings({ theme, color }));
//   };

//   if (!user) return <p>Loading...</p>;

//   return (
//     <Box
//       sx={{
//         padding: 2,
//         backgroundColor: 'var(--background-color)',
//         color: 'var(--text-color)',
//       }}
//     >
//       <Button
//         onClick={() => navigate(-1)}
//         startIcon={<ArrowBack />}
//         sx={{ color: 'var(--primary-color)' }}
//       >
//         Back
//       </Button>
//       <Typography
//         variant="h5"
//         sx={{ color: 'var(--primary-color)', marginBottom: 2 }}
//       >
//         Profile
//       </Typography>

//       <Typography variant="body1" sx={{ marginBottom: 2 }}>
//         <strong>Username:</strong> {user.username}
//       </Typography>
//       <Typography variant="body1" sx={{ marginBottom: 2 }}>
//         <strong>Email:</strong> {user.email}
//       </Typography>

//       <SubscriptionStatus />
//       {subscriptionStatus === 'none' && <SubscriptionButton />}
//       {subscriptionStatus !== 'none' && (
//         <Button
//           variant="contained"
//           color="error"
//           onClick={handleCancelSubscription}
//           sx={{ marginBottom: 2 }}
//         >
//           Cancel Subscription
//         </Button>
//       )}

//       <Box sx={{ marginTop: 4 }}>
//         <Typography variant="h5" sx={{ marginBottom: 2 }}>
//           Select Text Language
//         </Typography>
//         <TextField
//           fullWidth
//           select
//           label="Text Language"
//           value={textLanguage}
//           onChange={handleTextLanguageChange}
//           margin="normal"
//           InputProps={{
//             startAdornment: (
//               <InputAdornment position="start">
//                 <Language />
//               </InputAdornment>
//             ),
//           }}
//         >
//           <MenuItem value="de-DE">Deutsch (Deutschland)</MenuItem>
//           <MenuItem value="ru-RU">Русский (Россия)</MenuItem>
//           <MenuItem value="en-US">English (US)</MenuItem>
//           <MenuItem value="es-ES">Spanish (Spain)</MenuItem>
//           <MenuItem value="fr-FR">French (France)</MenuItem>
//         </TextField>

//         <Typography variant="h5" sx={{ marginBottom: 2 }}>
//           Select Translation Language
//         </Typography>
//         <TextField
//           fullWidth
//           select
//           label="Translation Language"
//           value={translationLanguage}
//           onChange={handleTranslationLanguageChange}
//           margin="normal"
//           InputProps={{
//             startAdornment: (
//               <InputAdornment position="start">
//                 <Language />
//               </InputAdornment>
//             ),
//           }}
//         >
//           <MenuItem value="de-DE">Deutsch (Deutschland)</MenuItem>
//           <MenuItem value="ru-RU">Русский (Россия)</MenuItem>
//           <MenuItem value="en-US">English (US)</MenuItem>
//           <MenuItem value="es-ES">Spanish (Spain)</MenuItem>
//           <MenuItem value="fr-FR">French (France)</MenuItem>
//         </TextField>
//       </Box>

//       <Box sx={{ marginTop: 4 }}>
//         <Typography variant="h5" sx={{ marginBottom: 2 }}>
//           Select Text Voice
//         </Typography>
//         {loadingVoices ? (
//           <CircularProgress />
//         ) : (
//           <TextField
//             fullWidth
//             select
//             label="Text Voice"
//             value={textVoice}
//             onChange={handleTextVoiceChange}
//             margin="normal"
//             InputProps={{
//               startAdornment: (
//                 <InputAdornment position="start">
//                   <RecordVoiceOver />
//                 </InputAdornment>
//               ),
//             }}
//           >
//             {textVoices.map((voice) => (
//               <MenuItem key={voice.name} value={voice.name}>
//                 {voice.name}
//               </MenuItem>
//             ))}
//           </TextField>
//         )}

//         <Typography variant="h5" sx={{ marginBottom: 2 }}>
//           Select Translation Voice
//         </Typography>
//         {loadingVoices ? (
//           <CircularProgress />
//         ) : (
//           <TextField
//             fullWidth
//             select
//             label="Translation Voice"
//             value={translationVoice}
//             onChange={handleTranslationVoiceChange}
//             margin="normal"
//             InputProps={{
//               startAdornment: (
//                 <InputAdornment position="start">
//                   <RecordVoiceOver />
//                 </InputAdornment>
//               ),
//             }}
//           >
//             {translationVoices.map((voice) => (
//               <MenuItem key={voice.name} value={voice.name}>
//                 {voice.name}
//               </MenuItem>
//             ))}
//           </TextField>
//         )}
//       </Box>

//       <Box sx={{ marginTop: 4 }}>
//         <FormControlLabel
//           control={
//             <Switch checked={theme === 'dark'} onChange={handleToggleTheme} />
//           }
//           label="Dark Theme"
//           sx={{ marginBottom: 2 }}
//         />

//         <TextField
//           fullWidth
//           select
//           label="Select Theme Color"
//           value={themeColor}
//           onChange={handleThemeColorChange}
//           margin="normal"
//           InputProps={{
//             startAdornment: (
//               <InputAdornment position="start">
//                 <Palette />
//               </InputAdornment>
//             ),
//           }}
//         >
//           {colorSchemes.map((scheme) => (
//             <MenuItem key={scheme.value} value={scheme.value}>
//               {scheme.name}
//             </MenuItem>
//           ))}
//         </TextField>

        
//       </Box>

//       <ToastContainer />
//     </Box>
//   );
// };

// export default UserProfile;



import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  setTextLanguage,
  setTranslationLanguage,
  setTextVoice,
  setTranslationVoice,
  checkSubscriptionStatus,
  cancelSubscription,
} from '../redux/textsSlice';
import { fetchUserProfile, saveUserSettings } from '../redux/authSlice';
import { toggleTheme, setThemeColor, saveThemeSettings } from '../redux/themeSlice';
import SubscriptionButton from './SubscriptionButton';
import SubscriptionStatus from './SubscriptionStatus';
import {
  Box,
  Typography,
  Button,
  MenuItem,
  FormControlLabel,
  CircularProgress,
  TextField,
  InputAdornment,
  Switch,
} from '@mui/material';
import { ArrowBack, Language, RecordVoiceOver, Palette } from '@mui/icons-material';

const UserProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);
  const textLanguage = useSelector((state) => state.texts.textLanguage);
  const translationLanguage = useSelector((state) => state.texts.translationLanguage);
  const textVoice = useSelector((state) => state.texts.textVoice);
  const translationVoice = useSelector((state) => state.texts.translationVoice);
  const subscriptionStatus = useSelector((state) => state.texts.subscriptionStatus);
  const theme = useSelector((state) => state.theme.theme);
  const themeColor = useSelector((state) => state.theme.color);

  const [isWebView, setIsWebView] = useState(false);
  const [textVoices, setTextVoices] = useState([]);
  const [translationVoices, setTranslationVoices] = useState([]);
  const [loadingVoices, setLoadingVoices] = useState(true);

  const colorSchemes = [
    { name: 'Gray', value: '#6c757d' },
    { name: 'Turquoise', value: '#00ced1' }, // Бирюзовый
    { name: 'Blue', value: '#03a9f4' }, // Голубой
    { name: 'Orange', value: '#ff8c42' }, // Оранжевый
    { name: 'Emerald', value: '#2ecc71' }, // Изумрудный
    { name: 'Purple', value: '#9b59b6' }, // Пурпурный
    { name: 'Pink', value: '#e84393' }, // Розовый
    { name: 'Gold', value: '#f1c40f' }, // Золотой
    { name: 'Coral', value: '#ff6f61' }, // Коралловый
    { name: 'Light Green', value: '#55efc4' }, // Светло-зеленый
    { name: 'Deep Blue', value: '#3498db' }, // Глубокий синий
    { name: 'Red', value: '#ff4757' }, // Красный
  ];

  // Загрузка профиля пользователя и статуса подписки при монтировании компонента
  useEffect(() => {
    dispatch(fetchUserProfile());
    dispatch(checkSubscriptionStatus());
    setIsWebView(!!window.ReactNativeWebView);
  }, [dispatch]);

  // Загрузка голосов для текста и перевода
  useEffect(() => {
    setLoadingVoices(true);

    const loadVoices = () => {
      if (window.speechSynthesis) {
        const synth = window.speechSynthesis;
        const availableVoices = synth.getVoices();
        setTextVoices(availableVoices.filter((voice) => voice.lang.startsWith(textLanguage.split('-')[0])));
        setTranslationVoices(availableVoices.filter((voice) => voice.lang.startsWith(translationLanguage.split('-')[0])));
        setLoadingVoices(false);
      } else {
        console.error('SpeechSynthesis is not supported in this browser.');
        setLoadingVoices(false);
      }
    };

    if (isWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'GET_VOICES', lang: textLanguage, target: 'text', comp: 'UserProfile' }));
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'GET_VOICES', lang: translationLanguage, target: 'translation', comp: 'UserProfile' }));
    } else {
      if (window.speechSynthesis) {
        window.speechSynthesis.onvoiceschanged = loadVoices;
      }
      loadVoices();
    }

    const handleMessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.type === 'VOICES_RESPONSE') {
          if (data.target === 'text') {
            setTextVoices(data.voices);
          } else if (data.target === 'translation') {
            setTranslationVoices(data.voices);
          }
          setLoadingVoices(false);
        }
      } catch (error) {
        console.error('Invalid JSON:', event.data);
      }
    };

    document.addEventListener('message', handleMessage);
    return () => {
      document.removeEventListener('message', handleMessage);
    };
  }, [textLanguage, translationLanguage, isWebView]);

  // Установка голосов по умолчанию
  useEffect(() => {
    if (textVoices.length > 0 && !textVoice) {
      dispatch(setTextVoice(textVoices[0].name));
    }
  }, [textVoices, textVoice, dispatch]);

  useEffect(() => {
    if (translationVoices.length > 0 && !translationVoice) {
      dispatch(setTranslationVoice(translationVoices[0].name));
    }
  }, [translationVoices, translationVoice, dispatch]);

  // Обработчики изменения языка текста и перевода
  const handleTextLanguageChange = (e) => {
    const language = e.target.value;
    dispatch(setTextLanguage(language));
    dispatch(saveUserSettings({ text_language: language }));
    if (isWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'GET_VOICES', lang: language, target: 'text', comp: 'UserProfile' }));
    } else {
      const synth = window.speechSynthesis;
      const availableVoices = synth.getVoices();
      setTextVoices(availableVoices.filter((voice) => voice.lang.startsWith(language.split('-')[0])));
    }
  };

  const handleTranslationLanguageChange = (e) => {
    const language = e.target.value;
    dispatch(setTranslationLanguage(language));
    dispatch(saveUserSettings({ translation_language: language }));
    if (isWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'GET_VOICES', lang: language, target: 'translation', comp: 'UserProfile' }));
    } else {
      const synth = window.speechSynthesis;
      const availableVoices = synth.getVoices();
      setTranslationVoices(availableVoices.filter((voice) => voice.lang.startsWith(language.split('-')[0])));
    }
  };

  // Обработчики изменения голосов
  const handleTextVoiceChange = (e) => {
    const voice = e.target.value;
    dispatch(setTextVoice(voice));
    dispatch(saveUserSettings({ text_voice: voice }));
  };

  const handleTranslationVoiceChange = (e) => {
    const voice = e.target.value;
    dispatch(setTranslationVoice(voice));
    dispatch(saveUserSettings({ translation_voice: voice }));
  };

  // Обработчик отмены подписки
  const handleCancelSubscription = async () => {
    try {
      await dispatch(cancelSubscription()).unwrap();
      toast.success('Subscription canceled successfully.');
    } catch (error) {
      console.error('Failed to cancel subscription:', error);
      toast.error('Failed to cancel subscription.');
    }
  };

  // Обработчик переключения темы
  const handleToggleTheme = async () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    dispatch(toggleTheme());

    // Сохраняем настройки темы
    dispatch(saveThemeSettings({ theme: newTheme, color: themeColor }));
  };

  // Обработчик изменения цветовой схемы
  const handleThemeColorChange = async (e) => {
    const color = e.target.value;
    dispatch(setThemeColor(color));

    // Сохраняем настройки цветовой схемы
    dispatch(saveThemeSettings({ theme, color }));
  };

  // Обработчик оплаты подписки звездами
  const handlePurchaseWithStars = async () => {
    if (window.Telegram && window.Telegram.WebApp) {
      const webApp = window.Telegram.WebApp;

      webApp.openInvoice({
        title: 'Purchase Subscription',
        description: 'Pay with Telegram Stars',
        currency: 'XTR', // Код валюты для Telegram Stars
        prices: [
          { label: '50 Stars', amount: 5000 } // Сумма в минимальных единицах (1 звезда = 100 единиц)
        ],
        payload: JSON.stringify({ type: 'subscription_purchase', userId: user.id }),
        provider_token: '7871513784:AAH6KM6FL8wQGbom1nTjI3_8ZsSN7j5FYkI', // Замените на ваш токен
      }, (status) => {
        if (status === 'paid') {
          toast.success('Payment successful! Subscription activated.');
          dispatch(checkSubscriptionStatus()); // Обновляем статус подписки
        } else {
          toast.error('Payment failed. Please try again.');
        }
      });
    } else {
      toast.error('Telegram WebApp is not available.');
    }
  };

  if (!user) return <p>Loading...</p>;

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: 'var(--background-color)',
        color: 'var(--text-color)',
      }}
    >
      <Button
        onClick={() => navigate(-1)}
        startIcon={<ArrowBack />}
        sx={{ color: 'var(--primary-color)' }}
      >
        Back
      </Button>
      <Typography
        variant="h5"
        sx={{ color: 'var(--primary-color)', marginBottom: 2 }}
      >
        Profile
      </Typography>

      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        <strong>Username:</strong> {user.username}
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        <strong>Email:</strong> {user.email}
      </Typography>

      <SubscriptionStatus />
      {subscriptionStatus === 'none' && (
        <>
          <SubscriptionButton />
          <Button
            variant="contained"
            color="primary"
            onClick={handlePurchaseWithStars}
            sx={{ marginBottom: 2 }}
          >
            Purchase Subscription with Telegram Stars (50 Stars)
          </Button>
        </>
      )}
      {subscriptionStatus !== 'none' && (
        <Button
          variant="contained"
          color="error"
          onClick={handleCancelSubscription}
          sx={{ marginBottom: 2 }}
        >
          Cancel Subscription
        </Button>
      )}

      <Box sx={{ marginTop: 4 }}>
        <Typography variant="h5" sx={{ marginBottom: 2 }}>
          Select Text Language
        </Typography>
        <TextField
          fullWidth
          select
          label="Text Language"
          value={textLanguage}
          onChange={handleTextLanguageChange}
          margin="normal"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Language />
              </InputAdornment>
            ),
          }}
        >
          <MenuItem value="de-DE">Deutsch (Deutschland)</MenuItem>
          <MenuItem value="ru-RU">Русский (Россия)</MenuItem>
          <MenuItem value="en-US">English (US)</MenuItem>
          <MenuItem value="es-ES">Spanish (Spain)</MenuItem>
          <MenuItem value="fr-FR">French (France)</MenuItem>
        </TextField>

        <Typography variant="h5" sx={{ marginBottom: 2 }}>
          Select Translation Language
        </Typography>
        <TextField
          fullWidth
          select
          label="Translation Language"
          value={translationLanguage}
          onChange={handleTranslationLanguageChange}
          margin="normal"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Language />
              </InputAdornment>
            ),
          }}
        >
          <MenuItem value="de-DE">Deutsch (Deutschland)</MenuItem>
          <MenuItem value="ru-RU">Русский (Россия)</MenuItem>
          <MenuItem value="en-US">English (US)</MenuItem>
          <MenuItem value="es-ES">Spanish (Spain)</MenuItem>
          <MenuItem value="fr-FR">French (France)</MenuItem>
        </TextField>
      </Box>

      <Box sx={{ marginTop: 4 }}>
        <Typography variant="h5" sx={{ marginBottom: 2 }}>
          Select Text Voice
        </Typography>
        {loadingVoices ? (
          <CircularProgress />
        ) : (
          <TextField
            fullWidth
            select
            label="Text Voice"
            value={textVoice}
            onChange={handleTextVoiceChange}
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <RecordVoiceOver />
                </InputAdornment>
              ),
            }}
          >
            {textVoices.map((voice) => (
              <MenuItem key={voice.name} value={voice.name}>
                {voice.name}
              </MenuItem>
            ))}
          </TextField>
        )}

        <Typography variant="h5" sx={{ marginBottom: 2 }}>
          Select Translation Voice
        </Typography>
        {loadingVoices ? (
          <CircularProgress />
        ) : (
          <TextField
            fullWidth
            select
            label="Translation Voice"
            value={translationVoice}
            onChange={handleTranslationVoiceChange}
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <RecordVoiceOver />
                </InputAdornment>
              ),
            }}
          >
            {translationVoices.map((voice) => (
              <MenuItem key={voice.name} value={voice.name}>
                {voice.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Box>

      <Box sx={{ marginTop: 4 }}>
        <FormControlLabel
          control={
            <Switch checked={theme === 'dark'} onChange={handleToggleTheme} />
          }
          label="Dark Theme"
          sx={{ marginBottom: 2 }}
        />

        <TextField
          fullWidth
          select
          label="Select Theme Color"
          value={themeColor}
          onChange={handleThemeColorChange}
          margin="normal"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Palette />
              </InputAdornment>
            ),
          }}
        >
          {colorSchemes.map((scheme) => (
            <MenuItem key={scheme.value} value={scheme.value}>
              {scheme.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <ToastContainer />
    </Box>
  );
};

export default UserProfile;