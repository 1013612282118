import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { synthesizeSpeech, clearAudioUrl } from '../redux/ttsSlice';
import { Button, Box, Typography, Paper, Container, IconButton } from '@mui/material';
import { PlayArrow, Stop, Pause, ArrowBack } from '@mui/icons-material';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import { convert } from 'html-to-text';
import { isTelegramWebApp, transformLang } from './Utils';

const TextReader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { audioUrl } = useSelector((state) => state.tts);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPaused, setIsPaused] = useState(false); // Состояние для паузы
  const audioRef = useRef(null);
  const utteranceRef = useRef(null); // Ref для SpeechSynthesisUtterance

  // Получаем данные из location.state
  const { articleText, articleTitle, user } = location.state || {};

  // Получаем themeMode из Redux
  const themeMode = useSelector((state) => state.theme.theme);

  const stripHtmlTags = (html) => {
    return convert(html, {
      formatters: {
        'fooBlockFormatter': function (elem, walk, builder, formatOptions) {
          builder.openBlock({ leadingLineBreaks: formatOptions.leadingLineBreaks || 1 });
          walk(elem.children, builder);
          builder.addInline('!');
          builder.closeBlock({ trailingLineBreaks: formatOptions.trailingLineBreaks || 1 });
        }
      },
      selectors: [
        {
          selector: 'foo',
          format: 'fooBlockFormatter',
          options: { leadingLineBreaks: 1, trailingLineBreaks: 1 }
        }
      ]
    });
  };

  const handlePlay = () => {
    if (!user || !user.text_language) {
      console.error("User data is not available or text_language is missing.");
      return;
    }

    const cleanText = stripHtmlTags(articleText);
    console.log(cleanText);

    if (isTelegramWebApp()) {
      dispatch(synthesizeSpeech({ text: cleanText, lang: transformLang(user.text_language) }));
    } else if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'speak', lang: transformLang(user.text_language), text: cleanText, comp: 'TextReader', voice: user.text_voice }));
    } else {
      // Используем SpeechSynthesisUtterance для браузеров
      const newUtterance = new SpeechSynthesisUtterance(cleanText);
      newUtterance.lang = transformLang(user.text_language);
      utteranceRef.current = newUtterance;
      speechSynthesis.speak(newUtterance);
    }
    setIsPlaying(true);
    setIsPaused(false); // Сбрасываем паузу при начале воспроизведения
  };

  const handlePause = () => {
    if (isTelegramWebApp()) {
      // В Telegram пауза не поддерживается, поэтому просто останавливаем воспроизведение
      dispatch(synthesizeSpeech({ text: null, lang: null }));
      setIsPlaying(false);
      setIsPaused(false);
    } else if (window.ReactNativeWebView) {
      // В React Native отправляем сообщение для паузы
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'pause', comp: 'TextReader', voice: user.text_voice }));
      setIsPaused(true);
    } else {
      // Для браузеров используем speechSynthesis
      if (isPaused) {
        speechSynthesis.resume(); // Возобновляем воспроизведение
      } else {
        speechSynthesis.pause(); // Ставим на паузу
      }
      setIsPaused(!isPaused); // Переключаем состояние паузы
    }
  };

  const handleStop = () => {
    if (isTelegramWebApp()) {
      dispatch(synthesizeSpeech({ text: null, lang: null }));
    } else if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'stop', comp: 'TextReader', voice: user.text_voice }));
    } else {
      speechSynthesis.cancel();
    }
    setIsPlaying(false);
    setIsPaused(false); // Сбрасываем паузу при остановке
  };

  useEffect(() => {
    if (audioUrl) {
      setIsPlaying(true);
      const audio = new Audio(audioUrl);
      audioRef.current = audio;
      audio.play();

      // Обработчик завершения воспроизведения
      audio.addEventListener('ended', () => {
        setIsPlaying(false);
        setIsPaused(false);
        dispatch(clearAudioUrl());
      });

      // Обработчик паузы
      audio.addEventListener('pause', () => {
        setIsPaused(true);
      });

      // Обработчик возобновления воспроизведения
      audio.addEventListener('play', () => {
        setIsPaused(false);
      });
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.removeEventListener('ended', () => {});
        audioRef.current.removeEventListener('pause', () => {});
        audioRef.current.removeEventListener('play', () => {});
      }
    };
  }, [audioUrl, dispatch]);

  return (
    <Container>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h5" sx={{ ml: 2 }}>
          {articleTitle}
        </Typography>
      </Box>
      <Box mt={2} mb={2}>
        <Button
          variant="outlined"
          onClick={handlePlay}
          disabled={isPlaying && !isPaused} // Отключаем кнопку Play, если аудио уже играет и не на паузе
          startIcon={<PlayArrow />}
        >
          Play
        </Button>
        <Button
          variant="outlined"
          onClick={handlePause}
          disabled={!isPlaying} // Отключаем кнопку Pause, если аудио не играет
          startIcon={<Pause />}
          sx={{ ml: 1 }}
        >
          {isPaused ? 'Resume' : 'Pause'}
        </Button>
        <Button
          variant="outlined"
          onClick={handleStop}
          disabled={!isPlaying} // Отключаем кнопку Stop, если аудио не играет
          startIcon={<Stop />}
          sx={{ ml: 1 }}
        >
          Stop
        </Button>
      </Box>
      <Paper
        sx={{
          mb: 2,
          p: 2,
          backgroundColor: themeMode === "dark" ? "#1e1e1e" : "#ffffff",
          color: themeMode === "dark" ? "#e0e0e0" : "#000000",
          boxShadow: themeMode === "dark" ? "0px 2px 4px rgba(0, 0, 0, 0.5)" : "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <ReactQuill
          value={articleText}
          readOnly={true}
          theme="bubble"
          placeholder="Article text..."
          style={{
            height: 'auto',
            marginBottom: '10px',
            border: 'none',
          }}
          modules={{
            imageResize: {
              modules: ['Resize', 'DisplaySize', 'Toolbar'],
            },
            toolbar: false,
          }}
        />
      </Paper>
    </Container>
  );
};

export default TextReader;