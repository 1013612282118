// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import AsyncStorage from '@react-native-async-storage/async-storage';
// import api from '../axios';

// export const synthesizeSpeech = createAsyncThunk(
//   'tts/synthesizeSpeech',
//   async ({ text, lang }, { rejectWithValue }) => {
//     try {
//       const response = await api.post(
//         '/tts/',
//         { text, lang },
//         { responseType: 'blob' }
//       );
//       return URL.createObjectURL(response.data);
//     } catch (error) {

//         if (error.response?.status === 401) {
//             // Попробуйте обновить токен и повторить запрос
//             try {
//               const refreshToken = await AsyncStorage.getItem('refresh_token');
//               const refreshResponse = await api.post('/token/refresh/', { refresh: refreshToken });
//               await AsyncStorage.setItem('access_token', refreshResponse.data.access);
    
//               // Повторяем запрос с новым токеном
//               const response = await api.post(
//                 '/tts/',
//                 { text, lang },
//                 { responseType: 'blob' }
//               );
//               return URL.createObjectURL(response.data);
//             } catch (refreshError) {
//               // Если обновление токена не удалось, очищаем AsyncStorage и перенаправляем на страницу логина
//               await AsyncStorage.removeItem('access_token');
//               await AsyncStorage.removeItem('refresh_token');
//               window.location.href = '/login';
//               return rejectWithValue(refreshError.response?.data || 'Token refresh failed');
//             }
//           }

//       return rejectWithValue(error.message);
//     }
//   }
// );

// const ttsSlice = createSlice({
//   name: 'tts',
//   initialState: {
//     audioUrl: null,
//     isLoading: false,
//     error: null,
//   },
//   reducers: {},
//   extraReducers: (builder) => {
//     builder
//       .addCase(synthesizeSpeech.pending, (state) => {
//         state.isLoading = true;
//         state.error = null;
//       })
//       .addCase(synthesizeSpeech.fulfilled, (state, action) => {
//         state.isLoading = false;
//         state.audioUrl = action.payload;
//       })
//       .addCase(synthesizeSpeech.rejected, (state, action) => {
//         state.isLoading = false;
//         state.error = action.payload;
//       });
//   },
// });

// export default ttsSlice.reducer;


// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import AsyncStorage from '@react-native-async-storage/async-storage';
// import api from '../axios';

// export const synthesizeSpeech = createAsyncThunk(
//   'tts/synthesizeSpeech',
//   async ({ text, lang }, { rejectWithValue }) => {
//     try {
//       const response = await api.post(
//         '/tts/',
//         { text, lang },
//         { responseType: 'blob' }
//       );
//       return URL.createObjectURL(response.data);
//     } catch (error) {
//       if (error.response?.status === 401) {
//         // Попробуйте обновить токен и повторить запрос
//         try {
//           const refreshToken = await AsyncStorage.getItem('refresh_token');
//           const refreshResponse = await api.post('/token/refresh/', { refresh: refreshToken });
//           await AsyncStorage.setItem('access_token', refreshResponse.data.access);

//           // Повторяем запрос с новым токеном
//           const response = await api.post(
//             '/tts/',
//             { text, lang },
//             { responseType: 'blob' }
//           );
//           return URL.createObjectURL(response.data);
//         } catch (refreshError) {
//           // Если обновление токена не удалось, очищаем AsyncStorage и перенаправляем на страницу логина
//           await AsyncStorage.removeItem('access_token');
//           await AsyncStorage.removeItem('refresh_token');
//           window.location.href = '/login';
//           return rejectWithValue(refreshError.response?.data || 'Token refresh failed');
//         }
//       }

//       return rejectWithValue(error.message);
//     }
//   }
// );

// const ttsSlice = createSlice({
//   name: 'tts',
//   initialState: {
//     audioUrl: null,
//     isLoading: false,
//     error: null,
//   },
//   reducers: {
//     // Добавляем редюсер для очистки audioUrl
//     clearAudioUrl: (state) => {
//       state.audioUrl = null;
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(synthesizeSpeech.pending, (state) => {
//         state.isLoading = true;
//         state.error = null;
//       })
//       .addCase(synthesizeSpeech.fulfilled, (state, action) => {
//         state.isLoading = false;
//         state.audioUrl = action.payload;
//       })
//       .addCase(synthesizeSpeech.rejected, (state, action) => {
//         state.isLoading = false;
//         state.error = action.payload;
//       });
//   },
// });

// // Экспортируем action creator для clearAudioUrl
// export const { clearAudioUrl } = ttsSlice.actions;

// export default ttsSlice.reducer;





// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import AsyncStorage from '@react-native-async-storage/async-storage';
// import api from '../axios';

// export const synthesizeSpeech = createAsyncThunk(
//   'tts/synthesizeSpeech',
//   async ({ text, lang }, { rejectWithValue }) => {
//     try {
//       // Если текст пустой, останавливаем воспроизведение
//       if (!text) {
//         return null;
//       }

//       const response = await api.post(
//         '/tts/',
//         { text, lang },
//         { responseType: 'blob' }
//       );
//       return URL.createObjectURL(response.data);
//     } catch (error) {
//       if (error.response?.status === 401) {
//         // Попробуйте обновить токен и повторить запрос
//         try {
//           const refreshToken = await AsyncStorage.getItem('refresh_token');
//           const refreshResponse = await api.post('/token/refresh/', { refresh: refreshToken });
//           await AsyncStorage.setItem('access_token', refreshResponse.data.access);

//           // Повторяем запрос с новым токеном
//           const response = await api.post(
//             '/tts/',
//             { text, lang },
//             { responseType: 'blob' }
//           );
//           return URL.createObjectURL(response.data);
//         } catch (refreshError) {
//           // Если обновление токена не удалось, очищаем AsyncStorage и перенаправляем на страницу логина
//           await AsyncStorage.removeItem('access_token');
//           await AsyncStorage.removeItem('refresh_token');
//           window.location.href = '/login';
//           return rejectWithValue(refreshError.response?.data || 'Token refresh failed');
//         }
//       }

//       return rejectWithValue(error.message);
//     }
//   }
// );

// const ttsSlice = createSlice({
//   name: 'tts',
//   initialState: {
//     audioUrl: null,
//     isLoading: false,
//     error: null,
//   },
//   reducers: {
//     // Добавляем редюсер для очистки audioUrl
//     clearAudioUrl: (state) => {
//       state.audioUrl = null;
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(synthesizeSpeech.pending, (state) => {
//         state.isLoading = true;
//         state.error = null;
//       })
//       .addCase(synthesizeSpeech.fulfilled, (state, action) => {
//         state.isLoading = false;
//         state.audioUrl = action.payload;
//       })
//       .addCase(synthesizeSpeech.rejected, (state, action) => {
//         state.isLoading = false;
//         state.error = action.payload;
//       });
//   },
// });

// // Экспортируем action creator для clearAudioUrl
// export const { clearAudioUrl } = ttsSlice.actions;

// export default ttsSlice.reducer;




// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import AsyncStorage from '@react-native-async-storage/async-storage';
// import api from '../axios';

// // Экшен для синтеза речи
// export const synthesizeSpeech = createAsyncThunk(
//   'tts/synthesizeSpeech',
//   async ({ text, lang }, { rejectWithValue }) => {
//     try {
//       // Если текст пустой, останавливаем воспроизведение
//       if (!text) {
//         return null;
//       }

//       const response = await api.post(
//         '/tts/',
//         { text, lang },
//         { responseType: 'blob' }
//       );
//       return URL.createObjectURL(response.data);
//     } catch (error) {
//       if (error.response?.status === 401) {
//         // Попробуйте обновить токен и повторить запрос
//         try {
//           const refreshToken = await AsyncStorage.getItem('refresh_token');
//           const refreshResponse = await api.post('/token/refresh/', { refresh: refreshToken });
//           await AsyncStorage.setItem('access_token', refreshResponse.data.access);

//           // Повторяем запрос с новым токеном
//           const response = await api.post(
//             '/tts/',
//             { text, lang },
//             { responseType: 'blob' }
//           );
//           return URL.createObjectURL(response.data);
//         } catch (refreshError) {
//           // Если обновление токена не удалось, очищаем AsyncStorage и перенаправляем на страницу логина
//           await AsyncStorage.removeItem('access_token');
//           await AsyncStorage.removeItem('refresh_token');
//           window.location.href = '/login';
//           return rejectWithValue(refreshError.response?.data || 'Token refresh failed');
//         }
//       }

//       return rejectWithValue(error.message);
//     }
//   }
// );

// // Экшен для паузы воспроизведения
// export const pauseTTS = createAsyncThunk(
//   'tts/pauseTTS',
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await api.post('/tts/pause/');
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
// );

// // Экшен для возобновления воспроизведения
// export const resumeTTS = createAsyncThunk(
//   'tts/resumeTTS',
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await api.post('/tts/resume/');
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
// );

// const ttsSlice = createSlice({
//   name: 'tts',
//   initialState: {
//     audioUrl: null,
//     isLoading: false,
//     error: null,
//     isPaused: false, // Добавляем состояние паузы
//   },
//   reducers: {
//     // Добавляем редюсер для очистки audioUrl
//     clearAudioUrl: (state) => {
//       state.audioUrl = null;
//     },
//     // Добавляем редюсер для установки состояния паузы
//     setPaused: (state, action) => {
//       state.isPaused = action.payload;
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(synthesizeSpeech.pending, (state) => {
//         state.isLoading = true;
//         state.error = null;
//       })
//       .addCase(synthesizeSpeech.fulfilled, (state, action) => {
//         state.isLoading = false;
//         state.audioUrl = action.payload;
//       })
//       .addCase(synthesizeSpeech.rejected, (state, action) => {
//         state.isLoading = false;
//         state.error = action.payload;
//       })
//       .addCase(pauseTTS.fulfilled, (state) => {
//         state.isPaused = true; // Устанавливаем состояние паузы
//       })
//       .addCase(resumeTTS.fulfilled, (state) => {
//         state.isPaused = false; // Сбрасываем состояние паузы
//       });
//   },
// });

// // Экспортируем action creators
// export const { clearAudioUrl, setPaused } = ttsSlice.actions;

// export default ttsSlice.reducer;




import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AsyncStorage from '@react-native-async-storage/async-storage';
import api from '../axios';

// Экшен для синтеза речи
export const synthesizeSpeech = createAsyncThunk(
  'tts/synthesizeSpeech',
  async ({ text, lang }, { rejectWithValue }) => {
    try {
      // Если текст пустой, останавливаем воспроизведение
      if (!text) {
        return null;
      }

      const response = await api.post(
        '/tts/',
        { text, lang },
        { responseType: 'blob' }
      );
      return URL.createObjectURL(response.data);
    } catch (error) {
      if (error.response?.status === 401) {
        // Попробуйте обновить токен и повторить запрос
        try {
          const refreshToken = await AsyncStorage.getItem('refresh_token');
          const refreshResponse = await api.post('/token/refresh/', { refresh: refreshToken });
          await AsyncStorage.setItem('access_token', refreshResponse.data.access);

          // Повторяем запрос с новым токеном
          const response = await api.post(
            '/tts/',
            { text, lang },
            { responseType: 'blob' }
          );
          return URL.createObjectURL(response.data);
        } catch (refreshError) {
          // Если обновление токена не удалось, очищаем AsyncStorage и перенаправляем на страницу логина
          await AsyncStorage.removeItem('access_token');
          await AsyncStorage.removeItem('refresh_token');
          window.location.href = '/login';
          return rejectWithValue(refreshError.response?.data || 'Token refresh failed');
        }
      }

      return rejectWithValue(error.message);
    }
  }
);

// Экшен для паузы воспроизведения
export const pauseTTS = createAsyncThunk(
  'tts/pauseTTS',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.post('/tts/pause/');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Экшен для возобновления воспроизведения
export const resumeTTS = createAsyncThunk(
  'tts/resumeTTS',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.post('/tts/resume/');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const ttsSlice = createSlice({
  name: 'tts',
  initialState: {
    audioUrl: null,
    isLoading: false,
    error: null,
    isPaused: false, // Добавляем состояние паузы
  },
  reducers: {
    // Добавляем редюсер для очистки audioUrl
    clearAudioUrl: (state) => {
      state.audioUrl = null;
    },
    // Добавляем редюсер для установки состояния паузы
    setPaused: (state, action) => {
      state.isPaused = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(synthesizeSpeech.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(synthesizeSpeech.fulfilled, (state, action) => {
        state.isLoading = false;
        state.audioUrl = action.payload;
      })
      .addCase(synthesizeSpeech.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(pauseTTS.fulfilled, (state) => {
        state.isPaused = true; // Устанавливаем состояние паузы
      })
      .addCase(resumeTTS.fulfilled, (state) => {
        state.isPaused = false; // Сбрасываем состояние паузы
      });
  },
});

// Экспортируем action creators
export const { clearAudioUrl, setPaused } = ttsSlice.actions;

export default ttsSlice.reducer;