// import React from "react";
// import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
// import {
//   ColorLens as ColorLensIcon,
//   LibraryBooks as LibraryBooksIcon,
//   MenuBook as MenuBookIcon,
//   Home as HomeIcon,
//   Article as ArticleIcon, // Иконка для TextAnalyzer
// } from "@mui/icons-material";
// import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";

// const BottomNav = () => {
//   const navigate = useNavigate();
//   const [value, setValue] = React.useState(0);
//   const courseId = useSelector((state) => state.texts.courseId); // Получаем courseId из Redux

//   return (
//     <Paper
//       sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1000 }}
//       elevation={3}
//     >
//       <BottomNavigation
//         showLabels
//         value={value}
//         onChange={(event, newValue) => {
//           setValue(newValue);
//         }}
//       >
//         {/* Кнопка Home */}
//         <BottomNavigationAction
//           label="Home"
//           icon={<HomeIcon />}
//           onClick={() => navigate("/")}
//         />

//         {/* Кнопка Text Analyzer */}
//         <BottomNavigationAction
//           label="Analyzer"
//           icon={<ArticleIcon />}
//           onClick={() => navigate(`/text-analyzer/${courseId}`)}
//           disabled={!courseId} // Делаем кнопку неактивной, если курс не выбран
//         />

//         {/* Кнопка Theme Manager */}
//         <BottomNavigationAction
//           label="Themes"
//           icon={<ColorLensIcon />}
//           onClick={() => navigate("/theme-manager")}
//           disabled={!courseId} // Делаем кнопку неактивной, если курс не выбран
//         />

//         {/* Кнопка User Expressions */}
//         <BottomNavigationAction
//           label="Expressions"
//           icon={<LibraryBooksIcon />}
//           onClick={() => navigate("/user-expressions")}
//           disabled={!courseId} // Делаем кнопку неактивной, если курс не выбран
//         />

//         {/* Кнопка Full Dictionary */}
//         <BottomNavigationAction
//           label="Dictionary"
//           icon={<MenuBookIcon />}
//           onClick={() => navigate("/full-dictionary")}
//         />
//       </BottomNavigation>
//     </Paper>
//   );
// };

// export default BottomNav;

import React, { useState } from "react";
import { BottomNavigation, BottomNavigationAction, Paper, Drawer, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import {
  ColorLens as ColorLensIcon,
  LibraryBooks as LibraryBooksIcon,
  MenuBook as MenuBookIcon,
  Home as HomeIcon,
  Article as ArticleIcon,
  Menu as MenuIcon,
  Person as PersonIcon,
  ExitToApp as ExitToAppIcon,
  School as SchoolIcon, // Иконка для Manage Courses
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../redux/authSlice";

const BottomNav = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const courseId = useSelector((state) => state.texts.courseId); // Получаем courseId из Redux

  const handleLogout = async () => {
    try {
      await dispatch(logoutUser()).unwrap();
      navigate('/login');
    } catch (err) {
      alert('Logout failed. Please try again.');
    }
  };

  const handleProfileClick = () => {
    navigate('/profile');
  };

  const handleManageCourses = () => {
    navigate('/manage-courses');
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawerItems = (
    <List>
      <ListItem button onClick={handleManageCourses}>
        <ListItemIcon><SchoolIcon /></ListItemIcon>
        <ListItemText primary="Courses" />
      </ListItem>

      {/* Пункт "Themes" будет отключен, если курс не выбран */}
      <ListItem
        button
        onClick={() => navigate("/theme-manager")}
        disabled={!courseId} // Отключаем, если courseId отсутствует
      >
        <ListItemIcon><ColorLensIcon /></ListItemIcon>
        <ListItemText primary="Themes" />
      </ListItem>
      <ListItem button onClick={handleProfileClick}>
        <ListItemIcon><PersonIcon /></ListItemIcon>
        <ListItemText primary="Profile" />
      </ListItem>
      <ListItem button onClick={handleLogout}>
        <ListItemIcon><ExitToAppIcon /></ListItemIcon>
        <ListItemText primary="Exit" />
      </ListItem>
    </List>
  );

  return (
    <>
      <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1000 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction label="Home" icon={<HomeIcon />} onClick={() => navigate("/")} />
          <BottomNavigationAction
            label="Analyzer"
            icon={<ArticleIcon />}
            onClick={() => navigate(`/text-analyzer/${courseId}`)}
            disabled={!courseId} // Делаем кнопку неактивной, если курс не выбран
          />
          
          <BottomNavigationAction
            label="Expressions"
            icon={<LibraryBooksIcon />}
            onClick={() => navigate("/user-expressions")}
            disabled={!courseId} // Делаем кнопку неактивной, если курс не выбран
          />
          <BottomNavigationAction
            label="Dictionary"
            icon={<MenuBookIcon />}
            onClick={() => navigate("/full-dictionary")}
          />
          <BottomNavigationAction label="Menu" icon={<MenuIcon />} onClick={toggleDrawer(true)} />
        </BottomNavigation>
      </Paper>
      <Drawer anchor="bottom" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerItems}
      </Drawer>
    </>
  );
};

export default BottomNav;